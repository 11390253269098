import React from 'react'

const Contacts = () => {
    const GoWhatsapp = () => {
    window.open('https://wa.me/916262666671?text=Sir%2C%20I%20Need%20Id%20With%20Bonus', '_blank');


      }
    return (
        <div className='' style={{ padding: '20px' }} >
            <div className='HeadLine'  >Get Premium  Id with 3% Every Refill</div>
            <div style={{color:'white'}} >Get in touch on our Office Number</div>
                <div className='HeroBtn' onClick={GoWhatsapp} style={{ background: '#FFC000', color: 'white' }} > <i class="fa-brands fa-whatsapp"></i>  Get Bonus </div>
        </div>
    )
}

export default Contacts