import React from 'react'

const OurService = () => {
    const GoWhatsapp = () => {
          window.open('https://wa.me/916262666671?text=Sir%2C%20I%20Need%20Id%20With%20Bonus', '_blank');

      }
    return (
        <div>
            <div className='HeadLines' >Our Service</div>
                <div className='OSService'> <span style={{ fontSize: '17px', fontWeight: 'bold' }} >99 Online Book</span>  Is a 24x7 Online ID Proivider. We Have Supported over 20k+ participants to discover the best Online ID. Safty is our top preferrence and hence your data and details are secure and never be transferred or share.</div>
                <div className='HeroBtn' style={{ background: '#28D146', color: 'white' }} onClick={GoWhatsapp} >WhatsApp Us!</div>
            <div style={{ color: 'white', padding: '20px' }} >Premium Service Only</div>
        </div>
    )
}

export default OurService