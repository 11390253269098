import React from 'react'

const Post = (props) => {
    console.log(props.img);
  return (
    <div className='Post' >
        <img src={props.img} alt="" />
    </div>
  )
}

export default Post