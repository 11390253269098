import React from 'react'

const CustumerService = () => {
  const GoWhatsapp = () => {
    window.open('https://wa.me/916262666671?text=Sir%2C%20I%20Need%20Help%20In%20Online%20Id', '_blank');
  }
  
  return (
    <div className='TnCBox' style={{ padding: '20px' }} >
      <div className='HeadLine' >Customer Support </div>
      <div className='HeroP'>On WhatsApp</div>
      <div>Get In Touch With 99 Online BOOK CUSTOMER CARE
        For Queries, Complaints & How To Play, Contact us on</div>
      <div className='HeroBtn' style={{ background: '#FFC000' }} onClick={GoWhatsapp} >Get Support</div>
      <div style={{ marginTop: '20px' }} >We Are Here To Help You 24/7 With Our Online Services.</div>
    </div>
  )
}

export default CustumerService