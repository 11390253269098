import './App.css';
import Contacts from './Contacts';
import CustumerService from './CustumerService';
import OurService from './OurService';
import Post from './Post';


function App() {
  const GoWhatsapp = () => {
    window.open('https://wa.me/916262666671?text=Sir%2C%20I%20Need%20Id%20With%20Bonus', '_blank');
  }

  return (
    <>
      <Post img={'30P.jpeg'} />
      <div className='HeadLine' style={{ padding: '25px' }} >Get Id with 3% Every Refill</div>
      <div onClick={GoWhatsapp}> <Post img={'10P.jpeg'} />  </div>
      <Contacts />
      <Post img={'20P.jpeg'} />
      <CustumerService />
      <OurService />
    </>
  );
}

export default App;
